










































import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import Base64 from "@/utils/Base64";
import api from "@/api";
import { AttachmentDto, AttachmentHostType } from "@/api/appService";

@Component({
  name: "multipleUploadFile",
})
export default class multipleUploadFile extends Vue {
  @Model("change", { type: Array, default: () => [] }) readonly fileIds?:
    | number[]
    | undefined;

  @Prop({ required: false })
  hostType!: AttachmentHostType;

  @Prop({ required: true })
  hostId!: string | number;

  @Prop({required: false,type:Function,default:()=>{
    //
    }})
  beforeUpload?: undefined;

  @Prop({required:false,default:true})
  multiple?: boolean

  @Prop({required:false})
  returnUrl?: boolean

  @Prop({required: false})
  uploadText?: string

  @Prop({required:false})
  isInstructionsPath?: boolean

  @Prop({required:false})
  instructionsPath?: string

  attachmentList?: AttachmentDto[] = [];
  attachmentIds?: number[] = [];
  fileName = '';
  url = '';
  loading = false

  @Watch("instructionsPath")
  onValueChange(newVal:any,oldVal:any){
    if(oldVal === undefined){
      let file = newVal?.split('/') as any[]
      this.fileName = file[file.length - 1]
      this.url = newVal
    }
  }

  created() {
    let hostId = "";
    if (typeof this.hostId === "number") {
      hostId = this.hostId.toString();
    }
    if(!this.isInstructionsPath) {
      api.attachmentService
        .getAttachments({
          hostId: hostId,
          hostType: this.hostType,
        })
        .then((res) => {
          this.attachmentList = res;
          if (this.attachmentList!.length > 0) {
            this.attachmentList!.map((item: any) => {
              item.name = item.fileName;
              this.attachmentIds!.push(item.id);
            });
          }
          this.$emit("change", this.attachmentIds);
        });
    } else {
      //
    }
  }

  // 上传成功
  async onSuccess(res: any, file: any) {
    if (res.success) {
      this.loading = true
      if(this.multiple){
        if(this.returnUrl) {
          this.attachmentIds!.push(res.result.url);
        } else {
          this.attachmentIds!.push(res.result.attachmentId);
        }
        this.$emit("change", this.attachmentIds);
        this.loading = false
      } else {
        let attachment = undefined
        if(this.returnUrl) {
          attachment = res.result.url
        } else {
          attachment = res.result.attachmentId
        }
        this.$emit("change", attachment);
        if(this.isInstructionsPath) {
          this.url = res.result.url
          this.$emit("instructionsPathChange",res.result.url)
          this.fileName = res.result.fileName
          this.loading = false
        }
      }
    }
  }

  // 删除前
  beforeRemove(file: any) {
    return this.$confirm(`确定移除 ${file.name}？`);
  }

  // 删除成功
  onRemove(file: any) {
    this.$emit("onRemove", file);
    let index = this.attachmentIds!.indexOf(file.id);
    if (index == -1) {
      index = this.attachmentIds!.indexOf(file.response.result.attachmentId);
    }
    this.attachmentIds!.splice(index, 1);
    this.$emit("change", this.attachmentIds);
  }

  onPreview(file: any) {
    window.open(file.response.result.url);
  }

  get uploadParameter() {
    return { hostType: this.hostType };
  }

  get headers() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }

  handleDownload(url:any) {
    window.open(url, "_blank");
  }
}
